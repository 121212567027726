import React from "react";

import BlockContent from "@sanity/block-content-to-react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import scrollTo from "gatsby-plugin-smoothscroll";
import PropTypes from "prop-types";

import Button from "../../components/Button/Button";
import QuickInfosComponent from "../../components/QuickInfos/QuickInfos";
import GetOpeningHours from "../utils/GetOpeningHours";

const QuickInfos = ({ data }) => {
  const { t } = useTranslation();

  const openingHours = GetOpeningHours(data.i18n_lang);
  const lang = data.i18n_lang || "de-DE";

  return (
    <QuickInfosComponent
      button={<Button onClick={() => scrollTo("#infos", "start")} variant="secondary" color="black" title="Button">{t("arrival")}</Button>}
      date={new Date().toLocaleDateString(lang, { day: "numeric", month: "numeric" })}
      day={new Date().toLocaleDateString(lang, { weekday: "short" })}
      description={data._rawQuickInfoText && (<BlockContent blocks={data._rawQuickInfoText} />)}
      ticketText={t("ticketText")}
      // TODO: write hook to get settings and overall informations
      time={openingHours.string}
    />
  );
};

QuickInfos.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default QuickInfos;
