import React from "react";

import PropTypes from "prop-types";

const Arrival = (props) => {
  const {
    map,
    headline,
    children,
    description,
  } = props;

  return (
    <div className="grid lg:grid-cols-12 py-36 lg:py-90 gap-x-60 mx-18">
      <h2 className="h1 lg:col-start-2 lg:col-span-9">{headline}</h2>
      <div className="lg:col-span-6 -mt-8 lg:-mt-18">
        {map}
        {description && (<div className="bg-black text-white px-12 py-9 lg:px-20 lg:py-10">{description}</div>)}
      </div>
      <div className="grid mt-48 lg:-mt-18 lg:col-span-6 gap-y-30 lg:gap-y-0">{children}</div>
    </div>
  );
};

Arrival.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string,
  headline: PropTypes.string.isRequired,
  map: PropTypes.node.isRequired,
};

Arrival.defaultProps = {
  description: "",
};

export default Arrival;
