import React from "react";

import BlockContent from "@sanity/block-content-to-react";
import { graphql } from "gatsby";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";

import ArrivalComponent from "../../components/Arrival/Arrival";
import ArrivalContent from "../../components/ArrivalContent/ArrivalContent";
import Consent from "../../components/consent/consent";
import StaticMap from "../../components/Map/StaticMap";
import * as styles from "../../components/Map/StaticMap.module.scss";
import {
  CarIcon54, EnvelopeIcon36, OpeningIcon54, TrainIcon54,
} from "../../icons";
import GetAddress from "../utils/GetAddress";
import GetOpeningHours from "../utils/GetOpeningHours";

const Arrival = ({ data }) => {
  const addresses = GetAddress().data;
  const { language } = useI18next();
  const { t } = useTranslation();
  const openingHours = GetOpeningHours(language);
  let address = addresses[0];
  addresses.map((item) => {
    if (item.i18n_lang === language) {
      address = item;
    }
  });

  return (
    <ArrivalComponent
      headline="Info"
      map={(
        <Consent type="static-map" className={styles.staticMap}>
          <StaticMap
            lat={address.latitude}
            lng={address.longitude}
          />
        </Consent>
      )}
      id="arrival"
    >
      <ArrivalContent
        headline={t("contact")}
        icon={<EnvelopeIcon36 />}
      >
        <div className="mt-10 grid grid-cols-4">
          <div>{t("address")}:</div>
          <div className="col-span-3">
            <div>{address.addressline1}</div>
            <div>{address.addressline2}</div>
            <div>{address.street}</div>
            <div>{address.postal} {address.city}</div>
          </div>
        </div>
        <div className="mt-10 grid grid-cols-4">
          <div>{t("email")}:</div>
          <a className="col-span-3" href={`mailto:${address.email}`}>{address.email}</a>
        </div>
        <div className="mt-10 grid grid-cols-4">
          <div>{t("phoneNumberShort")}:</div>
          <a className="col-span-3" href={`tel:${address.phonenumber}`}>{address.phonenumber}</a>
        </div>
      </ArrivalContent>
      <ArrivalContent headline={t("OpeningHours")} icon={<OpeningIcon54 />}>
        <BlockContent blocks={openingHours.rawString} />
      </ArrivalContent>
      <ArrivalContent headline={t("byTrain")} icon={<TrainIcon54 />}>
        <BlockContent
          blocks={address._rawArrivalByTrain}
        />
      </ArrivalContent>
      <ArrivalContent headline={t("byCar")} icon={<CarIcon54 />}>
        <BlockContent
          blocks={address._rawArrivalByCar}
        />
      </ArrivalContent>
    </ArrivalComponent>
  );
};

Arrival.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default Arrival;
