import React from "react";

import PropTypes from "prop-types";
import {
  Autoplay,
  Keyboard,
  Mousewheel,
  Navigation,
} from "swiper";
// eslint-disable-next-line import/no-unresolved
import "swiper/css/bundle";
// eslint-disable-next-line import/no-unresolved,import/order
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";

import * as styles from "./ImageSlider.module.scss";

const ImageSlider = ({ children }) => {
  const navigationPosition = (swiper) => {
    try {
      const slides = swiper?.slides[swiper.activeIndex].getElementsByClassName("slide-image");

      const currentSlide = slides[0];

      swiper.navigation.nextEl.setAttribute("style", `top:${(currentSlide.clientHeight - 32) / 10}rem;`);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Swiper
      autoplay={{ delay: 3000 }}
      className={styles.swiper}
      loopedSlides={3}
      speed={3000}
      loop
      keyboard
      navigation
      mousewheel={{
        forceToAxis: true,
        thresholdTime: 1000,
      }}
      slideActiveClass={styles.slide}
      slidePrevClass={styles.slidePrev}
      onSlideChangeTransitionEnd={
        (swiper) => {
          if (swiper?.slides[swiper.activeIndex - 1] && swiper.slides[swiper.activeIndex + 1]) {
            swiper.slides[swiper.activeIndex - 1].classList.add(styles.slidePrev);
            swiper.slides[swiper.activeIndex + 1].classList.add(styles.slideNext);
          }
        }
      }
      onResize={navigationPosition}
      onInit={navigationPosition}
      onChange={navigationPosition}
      slideNextClass={styles.slideNext}
      modules={[Navigation, Mousewheel, Keyboard, Autoplay]}
    >
      {React.Children.map(children, (child) => <SwiperSlide>{child}</SwiperSlide>)}
    </Swiper>
  );
};

ImageSlider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default ImageSlider;
