import React from "react";

import PropTypes from "prop-types";

const SliderSlideCard = ({ overline, headline, button }) => (
  <div className="lg:px-42 lg:pt-48 lg:pb-24">
    <div className="text-body1-highlight mb-12">{overline}</div>
    <div className="text-h4 font-headings uppercase mb-24 break-words">{headline}</div>
    {button}
  </div>
);

SliderSlideCard.propTypes = {
  button: PropTypes.node.isRequired,
  headline: PropTypes.string.isRequired,
  overline: PropTypes.string.isRequired,
};

export default SliderSlideCard;
