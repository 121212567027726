import { graphql, useStaticQuery } from "gatsby";

const GetAddress = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityAddress {
        nodes {
          i18n_lang
          addressline1
          addressline2
          _rawArrivalByCar
          _rawArrivalByTrain
          city
          email
          faxnumber
          latitude
          longitude
          phonenumber
          postal
          street
        }  
      }
    }
  `);

  return {
    data: data.allSanityAddress.nodes || "/",
  };
};

export default GetAddress;
