import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import * as styles from "./SliderSlide.module.scss";

const SliderSlide = ({ image, card }) => (
  <div className="relative">
    <div className={classNames("flex", styles.aspect)}>{image}</div>
    {card && (<div className="slide-info mt-30 lg:mt-0 lg:left-0 lg:bottom-0 lg:bg-white lg:absolute lg:w-5/12">{card}</div>)}
  </div>
);

SliderSlide.propTypes = {
  card: PropTypes.node,
  image: PropTypes.node.isRequired,
};

SliderSlide.defaultProps = {
  card: null,
};

export default SliderSlide;
