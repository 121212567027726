import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";

import useDebounce from "../../hooks/haiku/useDebounce";
import useEventListener from "../../hooks/haiku/useEventListener";

import * as styles from "./StaticMap.module.scss";

const StaticMap = (props) => {
  const {
    lat,
    lng,
    zoom,
  } = props;

  const [staticMapUrl, setStaticMapUrl] = useState(null);

  const debouncedStaticMapUrl = useDebounce(staticMapUrl);

  const containerRef = useRef(null);

  const resize = () => {
    if (containerRef.current !== null) {
      const {
        offsetHeight,
        offsetWidth,
      } = containerRef.current;

      setStaticMapUrl(`https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=${zoom}&size=${offsetWidth}x${offsetHeight}&key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}`);
    }
  };

  useEffect(() => {
    resize();
  });

  useEventListener("resize", resize);

  return (

    <div
      className={styles.staticMap}
      style={{ backgroundImage: `url(${debouncedStaticMapUrl || staticMapUrl})` }}
      ref={containerRef}
    />
  );
};

StaticMap.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  zoom: PropTypes.number,
};

StaticMap.defaultProps = {
  zoom: 18,
};

export default StaticMap;
