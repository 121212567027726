import React from "react";

import { graphql } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import PropTypes from "prop-types";

import Button from "../../components/Button/Button";
import ImageSliderComponent from "../../components/ImageSlider/ImageSlider";
import SliderSlide from "../../components/SliderSlide/SliderSlide";
import SliderSlideCard from "../../components/SliderSlideCard/SliderSlideCard";
import GetCallToActionContext from "../utils/GetCallToActionContext";

const ImageSlider = ({ data }) => (
  <ImageSliderComponent>
    {data?.elements?.map((slide) => (
      slide.image && (
        <SliderSlide
          key={slide._key}
          image={(
            slide.image?.asset && (
              slide.callToAction?.title ? (
                <a href={GetCallToActionContext(slide.callToAction).path}>
                  <SanityImage
                    {...slide.image}
                    width={800}
                    alt={slide.image?.alt}
                    className="w-full"
                  />
                </a>
              ) : (
                <SanityImage
                  {...slide.image}
                  width={800}
                  alt={slide.image?.alt}
                  className="w-full"
                />
              )
            )
          )}
          card={slide.headline ? (
            <SliderSlideCard
              button={
                (
                  slide.callToAction?.title && (
                    <Button
                      aria-label={slide.callToAction?.title}
                      variant="secondary"
                      title={slide.callToAction?.title}
                      href={GetCallToActionContext(slide.callToAction).path}
                    >{slide.callToAction?.title}
                    </Button>
                  )
                )
              }
              overline={slide.overline}
              headline={slide.headline}
            />
          ) : null}
        />
      )
    ))}
  </ImageSliderComponent>
);

export const query = graphql`
  fragment ImageSlider on SanitySlider {
    elements {
      _key
      headline
      overline
      callToAction {
          linkType
          blank
          title
          href
          _rawInternalLink(resolveReferences: {maxDepth: 1})
        }
      image {
        ...ImageWithPreview
        alt
      }
    }
  }
`;

ImageSlider.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default ImageSlider;
