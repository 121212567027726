import React from "react";

import PropTypes from "prop-types";

const ArrivalContent = ({ icon, headline, children }) => (
  <div className="grid grid-cols-1 lg:grid-cols-12 lg:py-30">
    <div className="mb-12 h4 lg:col-start-2 lg:col-span-9">{icon} {headline}</div>
    <div className="text-body1 lg:col-start-2 lg:col-span-9">{children}</div>
  </div>
);

ArrivalContent.propTypes = {
  children: PropTypes.node.isRequired,
  headline: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};

export default ArrivalContent;
