import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import ContentContainer from "../ContentContainer/ContentContainer";

const ImageTextGrid = ({ children, headline, className }) => {
  const tmpChildren = [];
  for (let i = 0; i < children.length; i += 2) {
    if (i < children.length - 1) {
      tmpChildren.push([children[i], children[i + 1]]);
    } else {
      tmpChildren.push([children[i]]);
    }
  }

  return (
    <div className={classNames("py-36", className)}>
      <ContentContainer>
        <h2 className="h1">{headline}</h2>
      </ContentContainer>
      <div className="grid gap-y-48 gap-x-30 lg:gap-y-0 block -mt-8 lg:-mt-18">
        {tmpChildren.map((child1, index1) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="grid grid-cols-12 gap-x-30 lg:gap-x-0 lg:grid-cols-12 lg:gap-y-0 mx-18" key={`${index1}-${child1.key}`}>
            {child1.map((child2, index2) => {
              let tmpClassNames = "";

              if (typeof child2 === "object") {
                if (child2.type === "img" || child2.props?.className?.includes("image") || child2.type?.type?.displayName === "GatsbyImage" || typeof child2.type === "function") {
                  tmpClassNames += " col-span-11 lg:col-span-6 order-first lg:order-none imagegrid-image";

                  if (index2 === 0) {
                    tmpClassNames += " lg:-mt-0";
                  }

                  if (index2 % 2 === 0) {
                    tmpClassNames += " lg:col-span-6";
                  } else {
                    tmpClassNames += " lg:col-end-13 col-start-2 lg:col-span-6";
                  }
                } else {
                  tmpClassNames += "self-center mt-18 col-span-12 lg:col-span-4 lg:mt-0";

                  if (index2 % 2 === 0) {
                    tmpClassNames += " lg:col-start-2";
                  } else {
                    tmpClassNames += " lg:col-start-8";
                  }
                }
              } else {
                tmpClassNames += " col-span-12 lg:col-span-5 ";

                if (index2 % 2 === 0) {
                  tmpClassNames += " lg:col-start-2";
                } else {
                  tmpClassNames += " lg:col-start-8";
                }
              }

              return (
                <div className={tmpClassNames} key={child2.key}>
                  {child2}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

ImageTextGrid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  headline: PropTypes.string.isRequired,
};

ImageTextGrid.defaultProps = {
  className: "",
};

export default ImageTextGrid;
