import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import { OpeningIcon24, TicketIcon24 } from "../../icons";

import * as styles from "./QuicInfos.module.scss";

const QuickInfos = (props) => {
  const {
    day,
    date,
    ticketText,
    time,
    description,
    button,
  } = props;

  return (
    <div className="grid grid-cols-12 pt-24 pb-36 lg:pt-30 lg:pb-90 mt-90 mx-18">
      <div className="col-span-12 lg:col-start-2 lg:col-span-3 lg:pr-60">
        <div className="flex items-center gap-x-10 mb-15">
          <div className="h4 font-semibold">{day}</div>
          <div className="h4">{date}</div>
        </div>
        <div className="flex items-center gap-x-12 mb-6">
          <TicketIcon24 />
          <div className="text-body1-highlight">{ticketText}</div>
        </div>
        <div className="flex items-center gap-x-12 mb-15">
          <OpeningIcon24 />
          <div className="text-body1-highlight">{time}</div>
        </div>
        {button}
      </div>
      <div className="col-span-12 h-px my-30 bg-black lg:col-span-1 lg:h-full lg:w-px lg:my-0 lg:mx-30 lg:flex lg:justify-self-center" />
      <div className={classNames(styles.textblock, "col-span-12 text-body1-highlight lg:col-span-6 lg:pl-60")}>
        {description}
      </div>
    </div>
  );
};

QuickInfos.propTypes = {
  button: PropTypes.node.isRequired,
  date: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  ticketText: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};

export default QuickInfos;
