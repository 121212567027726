import React, { useEffect, useState } from "react";

import { useLocation } from "@reach/router";
import BlockContent from "@sanity/block-content-to-react";
import { graphql } from "gatsby";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import SanityImage from "gatsby-plugin-sanity-image";
import scrollTo from "gatsby-plugin-smoothscroll";
import PropTypes from "prop-types";

import Button from "../../components/Button/Button";
import Checkbox from "../../components/Checkbox/Checkbox";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import Eyecatcher from "../../components/Eyecatcher/Eyecatcher";
import InputField from "../../components/InputField/InputField";
import ImageTextGrid from "../../components/ImageTextGrid/ImageTextGrid";
import Section from "../../components/Section/Section";
import { EnvelopeIcon36 } from "../../icons";
import Arrival from "../Arrival/Arrival";
import ImageSlider from "../ImageSlider/ImageSlider";
import MainLayout from "../layouts/MainLayout";
import QuickInfos from "../QuickInfos/QuickInfos";
import Seo from "../Seo/Seo";
import GetCallToActionContext from "../utils/GetCallToActionContext";
import GetIconComponents from "../utils/GetIconComponents";

const IndexPage = ({ data: { page } }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const language = useI18next();
  const [currentAnchor, setCurrentAnchor] = useState();
  const [newsletterReg, setNewsletterReg] = useState();
  const [formError, setFormError] = useState();
  const [apiError, setApiError] = useState();
  const [policyChecked, setPolicyChecked] = useState();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    policy: false,
    surname: "",
  });
  const [formMessage, setFormMessage] = useState({
    name: "",
    email: "",
    surname: "",
    policy: "",
  });

  const handleInput = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    if (fieldName === "acceptPolicy") {
      setPolicyChecked(e.target.checked);
    }

    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));

    setFormError(false);

    if (!formData.name || formData.name?.length < 3) {
      setFormError(true);
      formMessage.name = "Bitte geben Sie Ihren Voramen an.";
      setFormData((prevState) => ({
        ...prevState,
      }));
    } else {
      formMessage.name = "";
    }

    if (!formData.surname || formData.surname?.length < 3) {
      setFormError(true);
      formMessage.surname = "Bitte geben Sie Ihren Nachnamen an.";
      setFormData((prevState) => ({
        ...prevState,
      }));
    } else {
      formMessage.surname = "";
    }
  };

  const validateEmail = (email) => String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

  const registerToNewletter = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Headers", "");
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Access-Control-Request-Method", "POST");

    setFormError(false);

    if (!formData.name || formData.name?.length < 3) {
      setFormError(true);
      formMessage.name = "Bitte geben Sie Ihren Voramen an.";
      setFormData((prevState) => ({
        ...prevState,
      }));
    } else {
      formMessage.name = "";
    }

    if (!formData.surname || formData.surname?.length < 3) {
      setFormError(true);
      formMessage.surname = "Bitte geben Sie Ihren Nachnamen an.";
      setFormData((prevState) => ({
        ...prevState,
      }));
    } else {
      formMessage.name = "";
    }

    if (!validateEmail(formData.email)) {
      setFormError(true);
      formMessage.email = "Bitte geben Sie eine gültige E-Mail ein.";
      setFormData((prevState) => ({
        ...prevState,
      }));
    } else {
      formMessage.email = "";
    }

    const raw = JSON.stringify({
      name: formData.name,
      vorname: formData.surname,
      eMail: formData.email,
      newsletter: [
        {
          name: "string",
          newsletterID: 27,
        },
      ],
    });

    const requestOptions = {
      mode: "cors",
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    if (!formError) {
      fetch("https://secure.gelsenkirchen.de/scoop/api/v7/public/abonniereNewsletter", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          setNewsletterReg(true);
        })
        .catch((error) => {
          console.log("error", error);
          setNewsletterReg(true);
          setApiError(true);
        });
    }
  };

  useEffect(() => {
    if (location.hash) {
      scrollTo(location.hash);

      // TODO: not loaded images result in wrong scroll position.
      if (currentAnchor !== location.hash) {
        setTimeout(() => {
          scrollTo(location.hash);
        }, 500);
        setCurrentAnchor(location.hash);
      }
    }
  }, []);

  return (
    <MainLayout>
      <Section>
        <ContentContainer>
          {page.sliderHeadline && (
            <h1>
              {page.sliderHeadline}
            </h1>
          )}
        </ContentContainer>
        <div className="-mt-8 lg:-mt-18">
          <ImageSlider data={page.slider} />
        </div>
      </Section>
      <Section idName="events">
        <QuickInfos data={page} />
      </Section>
      {page.museum?.elements && page.museum?.elements?.length > 0 && (
        <Section>
          {/* todo: Fix element */}
          <ImageTextGrid headline={t("exhibitions")}>
            {page.museum.elements.map(
              (element, index) => {
                if (element.image && element.image.asset) {
                  return (
                    <div className={index > 0 ? "image" : "image"} key={element._key}>
                      <SanityImage
                        {...element.image}
                        width={750}
                      />
                      <div className="bg-black text-white px-18">{element.image.alt}</div>
                    </div>
                  );
                }

                if (element.text) {
                  return (
                    <div key={element._key}>
                      <h3>{element.headline}</h3>
                      {element.text}
                      {GetCallToActionContext(element.callToAction).path !== "" && (
                        <div className="mt-10">
                          <Button
                            variant="secondary"
                            color="black"
                            title={element.callToAction?.title}
                            size="large"
                            href={GetCallToActionContext(element.callToAction).path}
                          >
                            {element.callToAction?.title}
                          </Button>
                        </div>
                      )}
                    </div>
                  );
                }

                return null;
              },
            )}
          </ImageTextGrid>
        </Section>
      )}
      {page.collections?.elements && page.collections.elements.length > 0 && (
        <Section>
          <ImageTextGrid headline={t("ourCollection")}>
            {page.collections.elements.map(
              (element, index) => {
                if (element.image && element.image.asset) {
                  return (
                    <div className={index > 0 ? "image" : "image"} key={element._key}>
                      <SanityImage
                        key={element._key}
                        {...element.image}
                        width={800}
                      />
                      <div className="text-body2-mobile lg:text-body2-desktop">{element.image.alt}</div>
                    </div>
                  );
                }

                if (element.text) {
                  return (
                    <div key={element._key}>
                      <h3>{element.headline}</h3>
                      {element.text}
                      {GetCallToActionContext(element.callToAction).path !== "" && (
                        <div className="mt-10">
                          <Button
                            variant="secondary"
                            color="black"
                            title={element.callToAction?.title}
                            size="large"
                            href={GetCallToActionContext(element.callToAction).path}
                          >
                            {element.callToAction?.title}
                          </Button>
                        </div>
                      )}
                    </div>
                  );
                }

                return null;
              },
            )}
          </ImageTextGrid>
        </Section>
      )}
      <div className="lg:grid lg:grid-cols-12">´
        <div className="lg:col-start-2 lg:col-end-12">
          <Eyecatcher
            headline={page.eyecatcher?.headline}
            button={(
              <Button
                color="white"
                title={page.eyecatcher?.callToAction?.title}
                size="small"
                href={GetCallToActionContext(page.eyecatcher?.callToAction).path}
              >
                {page.eyecatcher?.callToAction?.title}
              </Button>
            )}
            description={<BlockContent blocks={page.eyecatcher?._rawText} />}
            logo={React.createElement(
              GetIconComponents().components[page.eyecatcher?.Icon?.component],
            )}
          />
        </div>
      </div>
      {page.furtherSections?.elements?.length > 0 && (
        <Section idName="artEducation">
          <ImageTextGrid headline={t("artEducation")}>
            {page.furtherSections?.elements?.map(
              (element, index) => {
                if (element.image && element.image.asset) {
                  return (
                    <SanityImage
                      key={element._key}
                      {...element.image}
                      width={800}
                    />
                  );
                }

                if (element.text) {
                  return (
                    <div key={element._key}>
                      <h3>{element.headline}</h3>
                      {element.text}
                      {GetCallToActionContext(element.callToAction).path !== "" && (
                        <div className="mt-10">
                          <Button
                            variant="secondary"
                            color="black"
                            title={element.callToAction?.title}
                            size="large"
                            href={GetCallToActionContext(element.callToAction).path}
                          >
                            {element.callToAction?.title}
                          </Button>
                        </div>
                      )}
                    </div>
                  );
                }

                return null;
              },
            )}
          </ImageTextGrid>
        </Section>
      )}
      <Section idName="infos">
        <Arrival data={page} />
      </Section>
      {language.language !== "en-GB" && (
        <Section idName="newsletter">
          <Eyecatcher
            headline="Newsletter"
            button={
              newsletterReg ? (
                  apiError
                    ? <div>Ein Fehler ist aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.</div>
                    : <div>Vielen Dank für die Anmeldung. Sie erhalten in Kürze eine Bestätigungsmail.</div>
                )
                : (
                  <div className="mt-4">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-18 mt-10 text-black">
                      <InputField
                        name="name"
                        id="name"
                        label="Vorname:*"
                        color="white"
                        placeholder="Vorname"
                        message={formMessage.name}
                        value={formData.name}
                        onChange={handleInput}
                      />
                      <InputField
                        name="surname"
                        id="surname"
                        label="Nachname:*"
                        color="white"
                        placeholder="Nachname"
                        message={formMessage.surname}
                        value={formData.surname}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-18 mt-10 text-black">
                      <InputField
                        name="email"
                        id="email"
                        label="Email-Adresse:*"
                        color="white"
                        placeholder="Email-Adresse"
                        message={formMessage.email}
                        value={formData.email}
                        onChange={handleInput}
                      />
                      <div />
                    </div>
                    <div className="grid grid-cols-1 mt-10">
                      <Checkbox
                        name="acceptPolicy"
                        id="acceptCheckbox"
                        label="Ich bin einverstanden, dass die"
                        color="white"
                        message={formMessage.policy}
                        onChange={handleInput}
                      />
                      <label
                        htmlFor="acceptCheckbox"
                        className="text-body2 mt-10 mb-18"
                      >
                        Stadt Gelsenkirchen, Referat 41 – Kultur, Abteilung 41/M – Kunstmuseum,
                        Horster Str. 5-7, 45897 Gelsenkirchen, Telefon: 0209/169-4133<br />
                        meine oben genannten Daten sowie meine IP-Adresse und das Datum der Registrierung zu folgenden
                        Zwecken
                        <ul className="mt-10">
                          <li>- Zustellung des Newsletters einschließlich persönlicher Anrede</li>
                          <li>- Identifikation meiner Person zur Verhinderung des Missbrauchs des Dienstes und/oder
                            der E-Mail-Adresse
                          </li>
                        </ul>
                        <br />
                        wie folgt verarbeitet:<br />
                        <ul className="mt-10 mb-10">
                          <li>- erhebt</li>
                          <li>- speichert, und zwar bis zum Widerruf dieser Einwilligung bzw. bis zur Beendigung des
                            Newsletter-Abonnements
                          </li>
                        </ul>
                        Ich gebe diese Einwilligung freiwillig ab. Es entstehen mir keine Nachteile, wenn ich die
                        Einwilligung nicht erteile. Mir ist bekannt, dass ich meine Einwilligung mit Wirkung für die
                        Zukunft jederzeit widerrufen kann.<br />
                        Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung wird
                        durch den Widerruf nicht berührt.
                        Ich wurde informiert, dass bei einem Widerruf die Zustellung des Newsletters ausgeschlossen ist.
                        Die Datenschutzerklärung habe ich zur Kenntnis genommen.
                      </label>
                    </div>
                    <div className="text-right">
                      <Button
                        type="submit"
                        color="white"
                        title="Anmeldung zum Newsletter"
                        size="small"
                        onClick={registerToNewletter}
                        disabled={!policyChecked}
                      >
                        zum Newsletter anmelden
                      </Button>
                    </div>
                  </div>
                )
            }
            description={newsletterReg ? "" : "Bleiben Sie auf dem Laufenden: Abonnieren Sie Newsfeeds und Newsletter der Stadt Gelsenkirchen."}
            logo={<EnvelopeIcon36 />}
          />
        </Section>
      )}
    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) { 
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: sanityHomepage(id: {eq: $id}) {
      i18n_lang
      id
      Metadata {
        ...MetaData
      }
      sliderHeadline
      slider {
        ...ImageSlider
      }
      _rawQuickInfoText
      _rawMuseum
      _rawNewsletterSignUp
      _rawQuickInfoText
      _rawExhibitionText
      museum {
        _key
        elements {
          _key
          callToAction {
            linkType
            blank
            title
            href
            _rawInternalLink(resolveReferences: {maxDepth: 1})
          }
          headline
          text
          image {
            ...ImageWithPreview
            alt
          }
        }
      }
      exhibition {
        elements {
          _key
          text
          headline
          image {
            ...ImageWithPreview
            alt
          }
          callToAction {
            linkType
            blank
            title
            href
            _rawInternalLink(resolveReferences: {maxDepth: 5})
          }
        }
      }
      collections {
        elements {
          _key
          headline
          text
          image {
            asset {
              url
              gatsbyImageData(fit: FILLMAX, placeholder: DOMINANT_COLOR, width: 720)
            }
            ...ImageWithPreview
            alt
          }
          callToAction {
            linkType
            blank
            title
            href
            _rawInternalLink(resolveReferences: {maxDepth: 1})
          }
        }
      }
      eyecatcher {
        headline
        _rawText
        Icon {
          component
          icon {
            asset {
              url
              gatsbyImageData(fit: FILLMAX, placeholder: DOMINANT_COLOR, width: 720)
            }
          }
        }
        callToAction {
          linkType
          blank
          title
          href
          _rawInternalLink(resolveReferences: {maxDepth: 1})
        }
      }
      furtherSections {
        elements {
          _key
          headline
          text
          image {
            ...ImageWithPreview
            alt
          }
          callToAction {
            linkType
            blank
            title
            href
            _rawInternalLink(resolveReferences: {maxDepth: 1})
          }
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default IndexPage;
